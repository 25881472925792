import { commonEnv } from './environment.common';

export const environment = {
  ...commonEnv,
  environmentLabel: null,
  production: true,
  version: '1.10.143',
  commitHash: '96df606cd8409529cb775065f7b33abc4b6fe578',
  sentry: '',
  boomerangApiUrl: 'http://localhost:5001',
  auth: {
    serverUrl: 'https://auth.cloud.triarc-labs.com/auth/',
    clientId: 'boomerang',
    bearerExcludedUrls: ['http://localhost:4201'],
    scope: 'openid email profile offline_access',
    twoFaTimeLimit: 120,
    logoutUrl: 'http://localhost:4201',
  },
};
